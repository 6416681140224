<template>
	<div>
		<div class="top flex alit justc" style="position: relative;z-index: 999;">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/toplogo.png"
				style="width: 357px;height: 51px;cursor: pointer;" alt="" @click="goHome" />
			<div class="flex alit top-search">
				<div class="drop">
					<el-dropdown @command="commandChange">
						<span class="el-dropdown-link">
							{{ nowCommand }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="商品">商品</el-dropdown-item>
							<el-dropdown-item command="店铺">店铺</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="flex alit justc input-style">
					<el-input class="input-style-inner" v-model="searchName" type="text" placeholder="请输入商品\店铺"
						@change="goSearch" />
				</div>
				<div class="search" @click="goSearch">搜索</div>
			</div>
			<button class="css-button-arrow--red" @click="goRegister">商家入驻</button>
			<button class="css-button-arrow--red" style="margin-left: 10px;" @click="goMechart">商家后台</button>
			<el-popover placement="top" width="370" trigger="hover" style="margin-left: 15px;">
				<div class="" v-if="!isLogin" style="cursor: pointer;" @click="goLogin">
					您当前未登录,是否前往登录?<a style="color: red;margin-left: 10px;">立即前往 >></a>
				</div>
				<div class="" v-else>
					<div class="" v-if="carList.length > 0">
						<div class="car-list flex justb" v-for="item in carList">
							<div class="flex">
								<img :src="item.spu.picUrl" alt="">
								<div class="goods-name">{{ item.spu.name }}</div>
							</div>
							<div class="flexCol alit">
								<div class="goods-price">￥{{ item.sku.unitPrice }}</div>
								<div class="" style="cursor: pointer;margin-top: 20px;" @click="delShopCar(item)"><i
										class="el-icon-delete" style="font-size: 18px;"></i></div>
							</div>
						</div>
					</div>
					<div class="flexCol alit" v-else>
						<img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/goods/%E6%9A%82%E6%97%A0%E4%BF%A1%E6%81%AF-02.png"
							style="width: 400px;margin: 20px 0;" alt="">
						<div class="">采购车还没有商品，赶紧选购吧！</div>
					</div>
				</div>
				<div class="info-title" slot="reference" @click="goCar"><i class="el-icon-shopping-cart-2"
						style="margin-right: 5px;"></i>采购车{{ carList.length > 0 ? `(${carList.length})` : '' }}</div>
			</el-popover>
			<el-popover placement="top" width="330" trigger="hover" style="margin-left: 25px;">
				<div class="" style="cursor: pointer;" @click="goLogin" v-if="!userInfo">
					您当前未登录,是否前往登录?<a style="color: red;margin-left: 10px;">立即前往 >></a>
				</div>
				<div class="flexCol" v-else>
					<div class="flex alit justb" style="border-bottom: 1px solid #ccc;padding-bottom: 10px;">
						<div class="flex alit">
							<img :src="userInfo.avatar" style="width: 50px;height: 50px;" alt="">
							<div class="" style="margin: 0 20px;flex: 1;"> {{ userInfo.nickname }}</div>
						</div>
						<div class="login-out" @click="loginOut">退出登录</div>
					</div>
					<div class="flex">
						<div class="flexCol">

						</div>
					</div>
				</div>
				<div class="info-title" slot="reference" @click="!userInfo ? '' : $router.push('/userInfo')"><i
						class="el-icon-user" style="margin-right: 5px;"></i>我的</div>
			</el-popover>
		</div>
		<menus style="position: relative;z-index: 999;"></menus>
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" ref="child" @changeCar="changeCar"
				@getUser="getUser"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" ref="child" @changeCar="changeCar" @getUser="getUser"></router-view>
		<footers style="width: 100%;"></footers>
	</div>
</template>

<script>
import footers from './footer.vue'
import menus from './menu.vue'
export default {
	components: {
		footers, menus
	},
	data() {
		return {
			isLogin: localStorage.getItem('accessToken') ? true : false,
			searchName: '',
			nowCommand: '商品',
			carList: [],//购物车
			userInfo: null, //用户信息
		}
	},
	created() {
		this.changeCar()
		this.getUser()
	},
	methods: {
		goHome(){
			this.$router.push('/')
		},
		goMechart(){
			window.open('https://merchant.huoguogo.cn/')
		},
		//前往注册
		goRegister() {
			this.$router.push('/register')
		},
		//前往购物车
		goCar() {
			if (this.userInfo) {
				let rout = this.$router.currentRoute
				if (rout.path == '/shopCar') {
					return;
				}
				this.$router.push('/shopCar')
			}

		},
		//删除购物车数据
		delShopCar(item) {
			this.$confirm('是否从继续购物车中移除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$request('/app-api/trade/cart/delete', {
					ids: [item.id]
				}, 'delete').then(res => {
					console.log(res);
					this.$message({
						message: '移除成功！',
						type: 'success'
					});
					this.changeCar()
				})
			}).catch(() => {
			});
		},
		getUser() {
			this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
			this.isLogin = localStorage.getItem('accessToken') ? true : false
		},
		//获取购物车
		changeCar() {
			this.$request('/app-api/trade/cart/list', {}, 'get').then(res => {
				console.log(res);
				if (res) {
					this.carList = res.data.validList
				}

			})
		},
		commandChange(e) {
			this.nowCommand = e
		},
		//搜索
		goSearch() {
			let rout = this.$router.currentRoute
			if (rout.path == '/classfiySearch') {
				this.$refs.child.searchChange(this.searchName, this.nowCommand)
			} else {
				this.$router.push({
					path: '/classfiySearch',
					query: {
						searchName: this.searchName,
						type: 2,
						searchType: this.nowCommand
					}
				})
			}
		},
		loginOut() {
			this.$confirm('是否退出登录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				localStorage.clear()
				this.isLogin = false
				this.userInfo = null
				this.carList = []
				this.$message({
					type: 'success',
					message: '退出成功!'
				});
			}).catch(() => {

			});
		},
		//去登录
		goLogin() {
			this.$router.push('/login')
		}
	}
}
</script>

<style></style>
<style scoped lang="scss">
.login-out {
	width: 115px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 14px;
	font-family: Microsoft YaHei;
	color: #424242;
	background: rgba(226, 119, 31, .3);
	box-shadow: 0 5px 10px #00000029;
	border-radius: 3px;
	cursor: pointer;
}

.car-list {
	width: 100%;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 10px;
	padding-bottom: 10px;

	.goods-name {
		font-size: 14px;
		margin-left: 10px;
	}

	.goods-price {
		color: #c83732;
	}

	img {
		width: 60px;
		height: 60px;
	}
}

.info-title {
	color: #fff;
	cursor: pointer;
}

.top-search {
	border: 1px solid #fff;
	border-radius: 10px;
	height: 45px;
	margin: 0 10px;

	.search {
		color: #fff;
		padding: 0 20px;
		font-size: 14px;
		cursor: pointer;
	}

	.input-style {
		height: 45px;
		background-color: #fff;

		.input-style-inner {
			height: 45px;
			border: none;
			width: 300px;
		}
	}

	.drop {
		height: 45px;
		line-height: 45px;
		padding: 0 13px;

		.el-dropdown-link {
			color: #fff;
			cursor: pointer;
		}
	}

	::v-deep .el-input__inner {
		height: 45px !important;
		border: none;
		line-height: 45px !important;
	}
}



.top {
	background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012423.png');
	background-size: 100% 100%;
	width: 100%;
	height: 100px;
}
</style>