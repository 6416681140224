<!--  -->
<template>
    <div class="flexCol alit" style="background-color: #f0f0f0;">
        <div class="flex justb alit top-desc">
            <div class="flex">
                <div class="qbjg">全部结果</div>
                <div class="" style="color: #c83732;font-weight: 900;">{{ searchName }}</div>
            </div>
            <button class="back" @click="$router.go(-1)">
                返回
            </button>
        </div>

        <div class="shop-list">
            <div class="flex " style="border-bottom: 1px solid #f1f1f1;padding-bottom: 10px;" v-if="searchType == '商品'">
                <div class="" style="font-size: 15px;margin-right: 20px;">排序：</div>
                <div class="px" :style="{ color: pxIndex == 1 ? '#c83732' : '' }" @click="changeSearch(0, 1)">默认</div>
                <div class="flex px" style="margin: 0 20px;" :style="{ color: pxIndex == 2 ? '#c83732' : '' }"
                    @click="changeSearch('unitPrice', 2)">价格
                    <div class="flexCol" style="margin-left: 5px;">
                        <div class="up" :class="pxIndex == 2 && searchInfo.sortAsc ? 'up-check' : 'up-defalut'"></div>
                        <div class="down" :class="pxIndex == 2 && !searchInfo.sortAsc ? 'down-check' : 'down-defalut'">
                        </div>
                    </div>
                </div>
                <div class="flex px" :style="{ color: pxIndex == 3 ? '#c83732' : '' }"
                    @click="changeSearch('salesCount', 3)">销量
                    <div class="flexCol" style="margin-left: 5px;">
                        <div class="up" :class="pxIndex == 3 && searchInfo.sortAsc ? 'up-check' : 'up-defalut'"></div>
                        <div class="down" :class="pxIndex == 3 && !searchInfo.sortAsc ? 'down-check' : 'down-defalut'">
                        </div>
                    </div>
                </div>
            </div>
            <div class="tj-style flexCol">
                <div class="flex" style="flex-wrap: wrap;">
                    <div class="flexCol alit tj-list" v-for="(item, index) in shopList"
                        :style="{ marginRight: (index + 1) % 5 == 0 ? 0 : '20px' }" @click="goInfo(item)" :key="index">
                        <img :src="item.picUrl" style="" alt="">
                        <div class="tj-name textTwo">{{ item.name }}</div>
                        <div class="tj-price">
                            <div class="flex">
                                <div class="money-title">单价</div>
                                <div class="money-price">￥</div>
                                <div class="money-yuan">{{ item.unitPrice / 100 }}</div>
                                <div class="money-unit">/{{ item.unitName }}</div>
                            </div>
                            <div class="flex" style="margin-top: 5px;">
                                <div class="money-title">集采价</div>
                                <div class="money-price">￥</div>
                                <div class="money-yuan">{{ item.collectionPrice / 100 }}</div>
                                <div class="money-unit">/{{ item.collectionName }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="">
                <div class="store-list" v-for="item in storeList" :key="item.id">
                    <div class="flex justb alit" style="border-bottom: 1px solid #F3F4F5;padding-bottom: 20px;">
                        <div class="flex">
                            <img :src="item.shopLogo" style="width: 60px;height: 60px;margin-right: 30px;" alt="">
                            <div class="flexCol justb">
                                <div class="store-name"> {{ item.shopName }}</div>
                                <div class="store-addr">所在地：{{ item.city }}</div>
                            </div>
                        </div>
                        <div class="go-sotre">进入店铺>></div>
                    </div>

                    <div class="tj-style flexCol">
                        <div class="flex" style="overflow-x: scroll;padding-bottom: 20px;">
                            <div class="flexCol alit tj-list" v-for="(item, index) in item.spus"
                                :style="{ marginRight: (index + 1) % 5 == 0 ? 0 : '20px' }" @click="goInfo(item)"
                                :key="index">
                                <img :src="item.picUrl" style="" alt="">
                                <div class="tj-name textTwo">{{ item.name }}</div>
                                <div class="tj-price">
                                    <div class="flex">
                                        <div class="money-title">单价</div>
                                        <div class="money-price">￥</div>
                                        <div class="money-yuan">{{ item.unitPrice }}</div>
                                        <div class="money-unit">/{{ item.unitName }}</div>
                                    </div>
                                    <div class="flex" style="margin-top: 5px;">
                                        <div class="money-title">集采价</div>
                                        <div class="money-price">￥</div>
                                        <div class="money-yuan">{{ item.unitPrice }}</div>
                                        <div class="money-unit">/{{ item.unitName }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justc" style="width:100%;margin: 20px 0;">
                <div><el-pagination :page-size="pageSize3" :current-page="searchInfo.pageNo" small="small" layout="prev, pager, next, jumper"
                        :total="pageTotal" @current-change="handleCurrentChange" /></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            pageSize3: 20,
            pageTotal: 0,
            classfiyInfo: {},
            pxIndex: 1,
            searchInfo: {
                pageNo: 1,
                pageSize: 20,
                categoryId: '',
                categoryLevel: '',
                sortField: '',
                sortAsc: '',
                keyword: '', //商品搜索名称
                shopName: '',//店铺搜索名称
            },
            searchName: '',
            shopList: [], //商品列表
            searchType: '', //搜索类型
            storeList: [] //店铺列表
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //商品详情
        goInfo(item) {
            this.$router.push({
                path: '/goodsDetail',
                query: item
            })
        },
        //从搜索框进入
        searchChange(e, type) {
            this.searchInfo.sortField = ''
            this.searchInfo.sortAsc = ''
            this.searchInfo.pageNo = 1
            this.searchInfo.categoryId = ''
            this.searchInfo.categoryLevel = ''
            this.searchInfo.keyword = ''
            this.searchInfo.shopName = ''
            this.searchName = e
            this.searchType = type
            this.shopList = []
            this.storeList = []
            if (type == '商品') {
                this.searchInfo.keyword = e
                this.getClassFiyShop()
            } else {
                this.searchInfo.shopName = e
                this.getStore()
            }

        },
        //获取店铺列表
        getStore() {
            this.$request('/app-api/shop/info/page', this.searchInfo, 'get').then(res => {
                this.storeList = res.data.list
                this.pageTotal = res.data.total
            })
        },
        //获取商品列表
        getClassFiyShop() {
            this.$request('/app-api/product/spu/page', this.searchInfo, 'get').then(res => {
                this.shopList = res.data.list
                this.pageTotal = res.data.total
            })
        },
        handleCurrentChange(e) {
            this.searchInfo.pageNo = e
            if (this.searchType == '商品') {
                this.getClassFiyShop()
            } else {
                this.getStore()
            }
            window.scrollTo({
                top: 0,
                left: 0,
            });
        },
        //商品排序查询
        changeSearch(e, index) {

            if (e == 0) {
                this.searchInfo.sortField = ''
                this.searchInfo.sortAsc = ''
                this.searchInfo.pageNo = 1
            } else {
                this.searchInfo.sortField = e
                if (this.pxIndex == index) {
                    this.searchInfo.sortAsc = !this.searchInfo.sortAsc
                } else {
                    this.searchInfo.sortAsc = false
                }
                this.searchInfo.pageNo = 1
            }
            this.pxIndex = index
            this.getClassFiyShop()
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        let query = this.$route.query
        this.searchType = query.searchType
        if (query.type == 2) {
            this.searchChange(query.searchName, query.searchType)
            this.searchName = query.searchName
        } else {
            this.classfiyInfo = query
            this.searchName = query.name
            this.searchInfo.categoryId = this.classfiyInfo.id
            this.searchInfo.categoryLevel = this.classfiyInfo.level
            this.getClassFiyShop()
        }

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() {
        this.searchInfo.pageNo = 1
        let query = this.$route.query
        this.searchType = query.searchType
        if (query.type == 2) {
            this.searchChange(query.searchName, query.searchType)
            this.searchName = query.searchName
        } else {
            this.classfiyInfo = query
            this.searchName = query.name
            this.searchInfo.categoryId = this.classfiyInfo.id
            this.searchInfo.categoryLevel = this.classfiyInfo.level
            this.getClassFiyShop()
        }

     } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.store-list {
    width: 1200px;
    border: 1px solid #F3F4F5;
    padding: 20px;
    margin-bottom: 20px;

    .store-name {
        font-size: 20px;
    }

    .store-addr {
        font-size: 14px;
        color: #999;
    }

    .go-sotre {
        color: #c5aa7b;
        cursor: pointer;
    }
}

.tj-style {
    // background-color: #070000;

    .tj-title-img {
        width: 77px;
        height: 18px;
    }

    .tj-list:hover {
        box-shadow: 0px 15px 25px -4px rgba(150, 150, 150, 0.24);
    }

    .tj-list {
        border: 1px solid #f1f1f1;
        padding-bottom: 5px;
        cursor: pointer;
        margin-top: 30px;

        img {
            width: 232px;
            height: 232px;
        }

        .tj-name {
            width: 210px;
            height: 40px;
            text-align: left;
            margin: 5px 0;
            font-size: 14px;
        }

        .tj-price {
            width: 92%;
            background-color: #fdf4f3;
            padding: 5px;
        }
    }
}

.down-check {
    border-top-color: #f50d05 !important;
}

.down-defalut {
    border-top-color: #c2c2c2 !important;
}

.up-check {
    border-bottom-color: #f50a02 !important;
}

.up-defalut {
    border-bottom-color: #c2c2c2 !important;
}

.px {
    font-size: 15px;
    cursor: pointer;
}

.down {
    width: 0;
    height: 0;
    border: 5px solid transparent;

    margin-top: 3px;
}

.up {
    width: 0;
    height: 0;
    border: 5px solid transparent;

}

.qbjg {
    font-size: 16px;
    margin-right: 30px;
}

.shop-list {
    width: 1250px;
    padding: 20px;
    background: #fff;
    margin-bottom: 40px;
}

.top-desc {
    width: 1250px;
    margin: 20px 0;
}
</style>