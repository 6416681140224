<template>
    <div class="bg flexCol alit">
        <div class="flex" style="position: relative;">
            <div class="classfiy" @mouseleave="otherList = []">
                <div class="list flex alit" v-for="item in classFiyList" @mouseenter="showOther(item)">
                    <div class="flex ">
                        <img :src="item.picUrl" alt="">
                        <div class="">
                            <div class="item" @click="goClassFiySearch(item, 1)">{{ item.name }}</div>
                            <div class="flex" style="flex-wrap: wrap;">
                                <div class="flex ite-child" v-for="ite in item.children"
                                    @click="goClassFiySearch(ite, 2)">
                                    {{ ite.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-other" v-if="otherList.length > 0">
                    <div style="margin-bottom: 30px;" v-for="item in otherList">
                        <div class="item" @click="goClassFiySearch(item, 2)">{{ item.name }}</div>
                        <div class="flex" style="flex-wrap: wrap;">
                            <div class="flex ite-child" v-for="ite in item.children" @click="goClassFiySearch(ite, 3)">
                                {{ ite.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexCol justb" style="margin-left: 10px;">
                <div class="flex">
                    <el-carousel indicator-position="none" height="360px" style="width: 758px;height: 360px;">
                        <el-carousel-item v-for="item in bannerList" :key="item.id">
                            <img :src="item.picUrl" style="width: 100%;height: 100%;" alt="" @click="openUrl(item.url)">
                        </el-carousel-item>
                    </el-carousel>
                    <div class="flexCol justb" style="margin-left: 10px;">
                        <video
                            src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/origin/6cdc21b0e6c9e6a5b146a9efe40657f2.mp4"
                            class="vide" controls autoplay></video>
                        <div class="flexCol alit justc saoma">
                            <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/appcode.png" alt="">
                            <div class="down">下载手机APP 随时随地买卖</div>
                        </div>
                    </div>
                </div>

                <div class="flex" style="overflow: hidden;height: 220px;width: 1010px;">
                    <!-- <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/%E7%BC%96%E7%BB%84%2010%402x.png"
                        alt="" style="width: 330px; height: 220px;border-radius: 10px;" />
                    <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/banner/1.jpg" alt=""
                        style="width: 330px; height: 220px;border-radius: 10px;" />
                    <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/banner/2.jpg" alt=""
                        style="width: 330px; height: 220px;border-radius: 10px;" /> -->

                    <vue-seamless-scroll :data="list" :class-option="classOption" style="width: 100%;">
                        <div class="flex">
                            <div v-for="(item, index) in list" :key="index">
                                <img :src="item" alt="" style="width: 330px; height: 220px" />
                            </div>
                        </div>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>
        <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/gg/%E9%87%8D%E5%BA%86%E7%81%AB%E9%94%85%E5%8D%8F%E4%BC%9Alogo%402x.png"
            style="width: 1350px;margin-top: 20px;" alt="">

        <!-- 协会推荐 -->
        <div class="tj-style flexCol">
            <div class="flex alit justc">
                <img class="tj-title-img"
                    src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/origin/left.png" alt="">
                <h1 class="" style="margin:0 20px;">协会推荐</h1>
                <img class="tj-title-img"
                    src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/origin/right.png" alt="">
            </div>
            <div class="flex" style="flex-wrap: wrap;">
                <div class="flexCol alit tj-list" v-for="(item, index) in xhtjList"
                    :style="{ marginRight: (index + 1) % 5 == 0 ? 0 : '20px' }" @click="goInfo(item)">
                    <img :src="item.picUrl" style="" alt="">
                    <div class="tj-name">{{ item.name }}</div>
                    <div class="tj-price">
                        <div class="flex">
                            <div class="money-title">单价</div>
                            <div class="money-price">￥</div>
                            <div class="money-yuan">{{ item.unitPrice / 100 }}</div>
                            <div class="money-unit">/{{ item.unitName }}</div>
                        </div>
                        <div class="flex" style="margin-top: 5px;">
                            <div class="money-title">集采价</div>
                            <div class="money-price">￥</div>
                            <div class="money-yuan">{{ item.collectionPrice / 100 }}</div>
                            <div class="money-unit">/{{ item.collectionName }}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 热销商品 -->
        <div class="tj-style flexCol">
            <div class="flex alit justc">
                <img class="tj-title-img"
                    src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/origin/left.png" alt="">
                <h1 class="" style="margin:0 20px;">热销商品</h1>
                <img class="tj-title-img"
                    src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/origin/right.png" alt="">
            </div>
            <div class="flex" style="flex-wrap: wrap;">
                <div class="flexCol alit tj-list" v-for="(item, index) in hotShopList"
                    :style="{ marginRight: (index + 1) % 5 == 0 ? 0 : '20px' }" @click="goInfo(item)">
                    <img :src="item.picUrl" style="" alt="">
                    <div class="tj-name">{{ item.name }}</div>
                    <div class="tj-price">
                        <div class="flex">
                            <div class="money-title">单价</div>
                            <div class="money-price">￥</div>
                            <div class="money-yuan">{{ item.unitPrice / 100 }}</div>
                            <div class="money-unit">/{{ item.unitName }}</div>
                        </div>
                        <div class="flex" style="margin-top: 5px;">
                            <div class="money-title">集采价</div>
                            <div class="money-price">￥</div>
                            <div class="money-yuan">{{ item.collectionPrice / 100 }}</div>
                            <div class="money-unit">/{{ item.collectionName }}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    name: 'Origin',
    components: {
        vueSeamlessScroll,
    },
    data() {
        return {
            classOption: {
                direction: 2
            },
            classFiyList: [], //分类列表
            otherList: [],  //分类扩展
            bannerList: [], //banner
            xhtjList: [], //协会推荐
            hotShopList: [], //热销商品
            list: ['https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/swiper/abc2bb809fb487fc7b9a99d124e6bfe.jpg',
                'https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/swiper/d520c75b84a5d79a9919c631c7a4877.jpg',
                'https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/swiper/f304f86b2826545c091f7b60e2d73f9.jpg',
                'https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/swiper/78fffd79e93a24ddd71a69ced1a5ab6.jpg',
                'https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/swiper/613d122c19169ec60f0210f0ccdf7a1.jpg'
            ],
            pageNo: 1,
            isRequest: false
        }
    },
    created() {
        this.getClassfiy() //获取分类列表
        this.getBanner() //获取banner列表
        this.getValueByKey('web.recommend') //获取商品推荐 web.recommend 协会推荐   web.burst 爆品  web.sales 热销商品
        // this.getValueByKey('web.sales') //获取商品推荐 web.recommend 协会推荐   web.burst 爆品  web.sales 热销商品
        this.getGoodsByPage()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll(e) {
            if (document.body.clientHeight - window.scrollY < 2000) {
                if (!this.isRequest) {
                    this.isRequest = true
                    this.pageNo++
                    this.getGoodsByPage()
                }
            }
        },
        getGoodsByPage() {
            this.$request('/app-api/product/spu/page', {
                pageNo: this.pageNo,
                pageSize: 20,
            }, 'get').then(res => {
                this.isRequest = false
                this.hotShopList = this.hotShopList.concat(res.data.list)
                if (this.hotShopList.length == res.data.total) {
                    this.isRequest = true
                }
            })
        },
        //前往根据分类搜索商品
        goClassFiySearch(item, level) {
            item.level = level
            item.type = 1
            item.searchType = '商品'
            this.$router.push({
                path: '/classfiySearch',
                query: item
            })
        },
        //商品详情
        goInfo(item) {
            this.$router.push({
                path: '/goodsDetail',
                query: item
            })
        },
        getValueByKey(e) {
            this.$request('/app-api/infra/config/get-value-by-key', {
                key: e
            }, 'get').then(res => {
                this.$request('/app-api/product/spu/status-list-by-ids', {
                    ids: res.data.split(',')
                }, 'get').then(list => {
                    switch (e) {
                        case 'web.recommend':
                            this.xhtjList = list.data
                            // this.xhtjList = this.xhtjList.concat(list.data)
                            break;
                        case 'web.sales':
                            this.hotShopList = list.data
                            break;
                        default:
                            break;
                    }
                })
            })
        },
        //打开外部链接
        openUrl(e) {
            if (e) {
                window.open(e)
            }
        },
        getBanner() {
            this.$request('/app-api/promotion/banner/list', {
                position: 1
            }, 'get').then(res => {
                this.bannerList = res.data
            })
        },
        //鼠标移动到分类上显示详细分类信息
        showOther(e) {
            this.otherList = e.children
        },
        getClassfiy() {
            this.$request('/app-api/product/category/list', {}, 'get').then(res => {
                console.log(res);
                let data = res.data
                this.classFiyList = this.arrayToTree(data, 0)
            })
        },
        //将数据列表转换为树形结构
        arrayToTree(nodes, parentId) {
            return nodes.filter((node) => node.parentId === parentId).map((node) => ({
                ...node, children: this.arrayToTree(nodes, node.id)
            }))
        }
    }

}
</script>
<style scoped lang="scss">
.tj-style {
    width: 1280px;
    background-color: #fff;
    padding: 20px 20px 50px 50px;
    margin-top: 20px;

    .tj-title-img {
        width: 77px;
        height: 18px;
    }

    .tj-list:hover {
        box-shadow: 0px 15px 25px -4px rgba(150, 150, 150, 0.24);
    }

    .tj-list {
        border: 1px solid #f1f1f1;
        padding-bottom: 5px;
        cursor: pointer;
        margin-top: 30px;

        img {
            width: 232px;
            height: 232px;
        }

        .tj-name {
            width: 90%;
            text-align: left;
            margin: 5px 0;
            font-size: 16px;
            max-width: 232px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .tj-price {
            width: 92%;
            background-color: #fdf4f3;
            padding: 5px;
        }
    }
}

.saoma {
    height: 160px;
    width: 240px;
    background-color: #fff;

    .down {}

    img {
        width: 115px;
        height: 115px;
    }
}

.vide {
    height: 190px;
    width: 240px;
    object-fit: cover;
}

.el-carousel__item h3 {
    color: #475669;
    height: 360px;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
    height: 360px;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
    height: 360px;
}

.item-other {
    width: 400px;
    height: 600px;
    position: absolute;
    left: 320px;
    top: 0;
    z-index: 99;
    background-color: #fff;
    padding-left: 10px;
    padding-top: 10px;
}

.classfiy::-webkit-scrollbar {
    width: 8px; //y轴宽度
    height: 10px; //x轴高度
    border-radius: 10px;
}

/*  滚动条轨道 */
.classfiy::-webkit-scrollbar-track {
    // background-color: #fb85251c;
    border-radius: 4px;
}

/*  滚动条上的滚动滑块 */
.classfiy::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 4px;
    cursor: pointer;
}

.classfiy {
    height: 600px;
    background-color: #fff;
    overflow-y: scroll;

    .item {
        font-size: 16px;
        text-align: left;
        font-weight: 900;
        cursor: pointer;
    }

    .item:hover {
        color: red;
    }

    .ite-child {
        font-size: 12px;
        color: #646464;
        margin-right: 10px;
        margin-top: 5px;
        cursor: pointer;
    }

    .ite-child:hover {
        color: red;
    }

    .list {
        height: 70px;
        padding-left: 20px;
        width: 300px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }


    }

    .list:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
    }

}

.bg {
    background-color: rgb(245, 245, 245);
}
</style>