import router from "@/router";
import axios from "axios";
import { Message } from "element-ui";
// create an axios instance
// const baseURL = "http://192.168.0.59:48080/"; //本地
const baseURL = 'https://app-api.huoguogo.cn/' //线上
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  baseURL,
  timeout: 300000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
    config.headers["Terminal"] = 40;
    let token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["tenant-id"] = "";
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    // console.log(response.data, "这里请求的接口返回");
    if (response.data && response.data.code == 0) {
      return response.data;
    } else if (response.data.code == 401) {
      if(localStorage.getItem("refreshToken")){
        return refreshToken(response.config);
      }else{
        router.push('login')
        return false
      }
    }else if (response.data.code == 400) {
      router.push('login')
      return response.data;
    } else {
      Message({
        message: response.data.msg,
        type: "warning",
      });
      return false;
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// Axios 无感知刷新令牌，参考 https://www.dashingdog.cn/article/11 与 https://segmentfault.com/a/1190000020210980 实现
let requestList = []; // 请求队列
let isRefreshToken = false; // 是否正在刷新中
const refreshToken = async (config) => {
  // 如果当前已经是 refresh-token 的 URL 地址，并且还是 401 错误，说明是刷新令牌失败了，直接返回 Promise.reject(error)
  if (config.url.indexOf("/member/auth/refresh-token") >= 0) {
    return Promise.reject("error");
  }

  // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
  if (!isRefreshToken) {
    isRefreshToken = true;
    // 1. 如果获取不到刷新令牌，则只能执行登出操作
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      Message({
        message: "当前未登录或登录已过期,请重新登录",
        type: "warning",
      });
      return Promise.reject({
        code: 401,
        msg: "当前未登录或登录已过期,请重新登录",
      });
    }
    // 2. 进行刷新访问令牌
    try {
      const refreshTokenResult = await req(
        "/app-api/member/auth/refresh-token?refreshToken=" + refreshToken,
        {},
        "post"
      );

      // AuthUtil.refreshToken(refreshToken)
      if (refreshTokenResult.code !== 0) {
        // 如果刷新不成功，直接抛出 e 触发 2.2 的逻辑
        // noinspection ExceptionCaughtLocallyJS
        console.log(refreshTokenResult);
        if(refreshTokenResult.code == 401){
          router.push('login')
        }
        throw new Error("刷新令牌失败");
      } else {
        localStorage.setItem(
          "accessToken",
          refreshTokenResult.data.accessToken
        );
      }

      // 2.1 刷新成功，则回放队列的请求 + 当前请求
      config.headers.Authorization =
        "Bearer " + refreshTokenResult.data.accessToken;
      requestList.forEach((cb) => {
        cb();
      });
      requestList = [];
      return request(config);
    } catch (e) {
      // 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
      // 2.2 刷新失败，只回放队列的请求
      requestList.forEach((cb) => {
        cb();
      });
      // 提示是否要登出。即不回放当前请求！不然会形成递归
      return Promise.reject({
        code: 401,
        msg: "当前未登录或登录已过期,请重新登录",
      });
    } finally {
      requestList = [];
      isRefreshToken = false;
    }
  } else {
    // 添加到队列，等待刷新获取到新的令牌
    return new Promise((resolve) => {
      requestList.push(() => {
        config.headers.Authorization =
          "Bearer " + localStorage.getItem("accessToken"); // 让每个请求携带自定义token 请根据实际情况自行修改
        resolve(request(config));
      });
    });
  }
};

const request = (config) => {
  return service(config);
};

const req = (url, data, meth) => {
  switch (meth) {
    case "post":
      return service.post(url, data);
      break;
    case "get":
      return service.get(
        url + (data == {} ? "" : "?" + new URLSearchParams(data).toString())
      );
      break;
    case "delete":
      return service.delete(
        url + (data == {} ? "" : "?" + new URLSearchParams(data).toString())
      );
      break;
    case "put":
      return service.put(url, data);
      break;
    default:
      break;
  }
};

export default req;
